import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../config';

export default class PlayScreen extends React.Component {

    state = {
        song: [],
        onesixzero: [],
        qOne: [],
        qTwo: [],
        browserdld: []
    }

    oszHandler = this.oszHandler.bind(this)
    browserHandler = this.browserHandler.bind(this)

    oszHandler() {
        this.setState({
            onesixzero: <div className="errres">
                <div className="mainerr">
                    <p className="errtxt">
                        Please wait while adding metadata into your song
                    </p>
                </div>
            </div>
        })
        axios.post(`${config.TAGWRITER_URL}/id3`, {
            song_url: this.state.song.media_url,
            cover_url: this.state.song.image,
            name: this.state.song.song,
            album: this.state.song.album,
            year: this.state.song.year,
            artist: this.state.song.singers
        })
            .then(res => {
                if (res.data.status === false) {
                    throw new Error()
                } else {
                    var url = res.data.url;
                    this.setState({
                        onesixzero: <div className="errres">
                            <div className="mainerr">
                                <p className="errtxt">
                                    Your song is Ready
                                </p>
                                <a href={url} className="activityb errsc">Download</a>
                            </div>
                        </div>
                    })
                }
            })
            .catch(err => {
                this.setState({
                    onesixzero: <div className="errres">
                        <div className="mainerr">
                            <p className="errtxt">
                                Sorry there was a problem to write metadata into your song. Try again later!
                            </p>
                        </div>
                    </div>
                })
            })
    }

    // browser tag writer

    browserHandler() {
        this.setState({
            onesixzero: <div className="errres">
                <div className="mainerr">
                    <p className="errtxt">
                        Please wait while adding metadata into your song
                    </p>
                </div>
            </div>
        })
        axios.post(`${config.TAGWRITER_URL}/id3`, {
            song_url: `https://aac.saavncdn.com${this.state.song.media_id}320.mp4`,
            cover_url: this.state.song.image,
            name: this.state.song.song,
            album: this.state.song.album,
            year: this.state.song.year,
            artist: this.state.song.singers
        })
            .then(res => {
                if (res.data.status === false) {
                    throw new Error()
                } else {
                    var url = res.data.url;
                    this.setState({
                        onesixzero: <div className="errres">
                            <div className="mainerr">
                                <p className="errtxt">
                                    Your song is Ready
                                </p>
                                <a href={url} className="activityb errsc">Download</a>
                            </div>
                        </div>
                    })
                }
            })
            .catch(err => {
                this.setState({
                    onesixzero: <div className="errres">
                        <div className="mainerr">
                            <p className="errtxt">
                                Sorry there was a problem to write metadata into your song. Try again later!
                            </p>
                        </div>
                    </div>
                })
            })
    }

    componentDidMount() {
        axios.get(`${config.API_URL}/song/${this.props.match.params.id}`)
            .then(res => {
                var song = res.data;

                if (song.result === "false") {
                    document.title = `Error | Musicder`
                    this.setState({ song })
                } else {
                    document.title = `Download ${song.song} | Musicder`
                    this.setState({
                        song,
                        qOne: song.media_url.replace("160.mp4", "96.mp4"),
                        qTwo: song.media_url.replace("160.mp4", "320.mp4")
                    })
                }
            })
    }

    render() {
        if (this.state.song.result === "false") {
            return (
                <div className="errres">
                    <div className="mainerr">
                        <p className="errtxt">
                            Sorry Nothing Found Please Search
                        </p>
                        <Link to={'../'}>
                            <p className="activityb errsc">Search</p>
                        </Link>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h1 className="dldheading">
                        Download {this.state.song.song}
                    </h1>
                    <p className="dldquality">
                        Please select your preferred quality (Metadata Added)
                    </p>
                    <div className="center">

                        <p onClick={this.oszHandler} className="dldactivityb">128 Kbps<br /><span className="smalltxt">Serverside Metadata Add</span></p><br />
                        <p onClick={this.browserHandler} className="dldactivityb">320 Kbps<br /><span className="smalltxt">Severside Metadata Add</span></p><br />
                        <a href={`${config.BACKUP_CDN}/?media_id=${this.state.song.media_id}&name=${this.state.song.song}`} className="dldactivityb">Backup<br /><span className="smalltxt">Use if others are not working</span></a><br />
                    </div>

                    {this.state.onesixzero}
                    {this.state.browserdld}

                    <div className="dldfooter">
                        <h1 className="playlogo">MUSICDER</h1>
                        <a className="atextdec" href={`https://github.com/cachecleanerjeet`}>
                            <p className="playparagone" >An Open Source Project by Tuhin</p>
                        </a>
                        <a href={`https://github.com/cachecleanerjeet/Musicder`}>
                            <img alt="Github" className="center" src="../img/github-black.svg" width="30" height="30" />
                        </a>
                    </div>
                </div>
            )
        }
    }
}
